<template>
  <div class="profile-config-form__field col-12">
    <div class="profile-config-form__field-title">{{ __('profile_config_form.level') }}</div>

    <div class="row">
      <div class="input-radio-chip__option col-4">
        <input
            :id="`profile-config-form__level--beginner-${_uid}`"
            type="radio" value="beginner" v-model="value"
        >
        <label :for="`profile-config-form__level--beginner-${_uid}`">
          {{ __('profile_config_form.level.beginner') }}
        </label>
      </div>

      <div class="input-radio-chip__option col-4">
        <input
            :id="`profile-config-form__level--advanced-${_uid}`"
            type="radio" value="advanced" v-model="value"
        >
        <label :for="`profile-config-form__level--advanced-${_uid}`">{{ __('profile_config_form.level.advanced') }}</label>
      </div>

      <div class="input-radio-chip__option col-4">
        <input
            :id="`profile-config-form__level--pro-${_uid}`"
            type="radio" value="pro" v-model="value"
        >
        <label :for="`profile-config-form__level--pro-${_uid}`">{{ __('profile_config_form.level.pro') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileConfigFormField from '@/mixins/profile-config-form-field';
import Locales from '@/mixins/locales';

export default {
  name: 'level',
  mixins: [
    Locales,
    ProfileConfigFormField('level', 'beginner'),
  ],
};
</script>
