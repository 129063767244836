import { mapActions } from 'vuex';

/**
 * Factory to create a Form field which updates a value on the Profile.
 * @param fieldName - The name of the field on the Profile to be updated
 * @param defaultValue - If the field is empty, this default will be filled in
 */
export default (fieldName, defaultValue) => ({
  props: {
    profile: Object,
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.profile[fieldName] || defaultValue;
  },
  watch: {
    value(value) {
      this.updatePerson({
        id: this.profile.id,
        [fieldName]: value,
      });
    },
  },
  methods: {
    ...mapActions('booking', [
      'updatePerson',
    ]),
  },
});
